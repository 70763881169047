<template>
    <div class="information">
        <div class="info-head">
            <span class="info-head-title">Product Detail</span>
        </div>
        <div class="info-body">
            <p>
                <span>ASIN</span>
                <span>{{ asin }}</span>
            </p>
            <p>
                <span>Brand</span>
                <span>{{ brandName }}</span>
            </p>
            <p>
                <span>Marketplace</span>
                <span>{{ MARKET_PLACE_MAP[countryCode] }}</span>
            </p>
            <p>
                <span>Category</span>
                <span>{{ category }}</span>
            </p>
            <p>
                <span>Price</span>
                <span>{{ currencySymbol }}{{ price }}</span>
            </p>
            <p>
                <span>Availiability</span>
                <span>{{ availiability }}</span>
            </p>
            <p>
                <span>Commission</span>
                <span>{{ commission }}
                    <PieChartOutlined @click="open = true" />
                </span>
            </p>
            <p>
                <span>Rating</span>
                <span>{{ productStarRating }}</span>
            </p>
            <p>
                <span>Best Seller Rank</span>
                <span>{{ bestSellerRank }}</span>
            </p>
            <p>
                <span># of Ratings</span>
                <span>{{ productNumRatings }}</span>
            </p>
            <p v-if="otherCountryCodes.length">
                <span>Other Marketplace</span>
                <span v-for="cc in otherCountryCodes">
                    <span>{{ MARKET_PLACE_MAP[cc] }}</span>
                </span>
            </p>
        </div>
        <CommissionChartModal v-model:open="open" :productId="productId" />
    </div>
</template>

<script setup>
import { ref, inject } from 'vue';
import { MARKET_PLACE_MAP } from '~/contants/publisher'
import { PieChartOutlined } from '@ant-design/icons-vue';
import CommissionChartModal from './commissionHistoryChartModal.vue';

const productId = inject('productId');

const { asin, brandName, category, currencySymbol, price, availiability, commission, productStarRating, productNumRatings, countryCode, otherCountryCodes, bestSellerRank } = defineProps({
    asin: String,
    brandName: String,
    category: String,
    currencySymbol: String,
    price: Number || null,
    availiability: String || null,
    commission: Number || null,
    productStarRating: String,
    productNumRatings: Number || null,
    countryCode: String,
    otherCountryCodes: Array,
    bestSellerRank: Number || null
})

const open = ref(false)
</script>

<style scoped lang="less">
.information {
    .info-head {
        display: flex;
        align-items: center;
        min-height: 48px;
        border-bottom: 1px solid #f0f0f0;

        .info-head-title {
            font-weight: 600;
            font-size: 16px;
        }
    }

    .info-body {
        display: grid;
        padding: 16px;
        gap: 16px;
        grid-template-columns: 1fr 1fr;

        >p {
            display: flex;
            align-items: center;
            justify-content: space-between
        }
    }
}
</style>