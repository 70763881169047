export const AVAILABILITY = ['All', 'In Stock', 'Out of Stock']

export const PRICE = [
  { label: 'All' },
  { label: 'Under %s10', max: 10 },
  { label: '%s10 to %s25', min: 10, max: 25 },
  { label: '%s25 to %s50', min: 25, max: 50 },
  { label: '%s50 to %s100', min: 50, max: 100 },
  { label: '%s100 to %s200', min: 100, max: 200 },
  { label: '%s200 & Above', min: 200 }
]

export const SORT = ['Best Sellers', 'Newly Added', 'Commission', 'Price']

export const MARKET_PLACE_MAP  = {
  'US': 'amazon.com',
  'UK': 'amazon.co.uk',
  'DE': 'amazon.de',
  'CA': 'amazon.ca',
  'FR': 'amazon.fr'
}