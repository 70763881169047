<template>
    <artmisads-modal class="link-tool" :width="960" v-model:open="open" :footer="null" :maskClosable="false">
        <template #title>
            <span class="title">Generate a Link</span>
        </template>
        <div>
            <div class="link-textarea">
                {{ modelRef.shortLinkSelect ? modelRef.shortLink : modelRef.trackingLink }}
            </div>
            <div class="link-short-link">
                <a-checkbox v-model:checked="modelRef.shortLinkSelect">Short Link</a-checkbox>
            </div>
            <a-collapse ghost class="link-collapse">
                <a-collapse-panel header="Advanced Options">
                    <template #extra>
                        <a-space>
                            <a-button @click.stop="onSave">
                                <template #icon>
                                    <SaveOutlined />
                                </template>
                                Save
                            </a-button>
                            <a-button @click.stop="onSaveAndCopy">
                                <template #icon>
                                    <CopyOutlined />
                                </template>
                                Save & Copy
                            </a-button>
                        </a-space>
                    </template>
                    <div class="link-advanced-option">
                        <div>
                            <p class="sub-title">
                                <span>Amazon tracking tag</span>
                            </p>
                            <div class="option-item">
                                <a-radio-group v-model:value="modelRef.amazonTagSelect" class="tracking-tag"
                                    @change="onChangeTrackLink">
                                    <a-radio value="default">
                                        <span>Use your default Amazon tracking tag</span>
                                        <a target="_blank">Set defalut Amazon tracking tag</a>
                                    </a-radio>
                                    <a-radio value="specific">
                                        <span>Use Speciﬁc Amazon tracking tag</span>
                                    </a-radio>
                                </a-radio-group>
                                <a-input maxLength="15" ref="trackTagInput"
                                    :disabled="modelRef.amazonTagSelect != 'specific'" class="track-tag-input"
                                    v-model:value="modelRef.tag" @keyup.enter="() => $refs.trackTagInput.blur()"
                                    @blur="onChangeTrackLink"></a-input>
                            </div>
                        </div>
                        <div>
                            <p class="sub-title">
                                <span>Source Options</span>
                                <a-tooltip>
                                    <template #title>
                                        A source is a method of classifying your links. For instance, a typical one
                                        could be
                                        a speciﬁc article ID or a domain name. You'll be capable of viewing a reporting
                                        breakdown for every source.
                                    </template>
                                    <QuestionCircleOutlined />
                                </a-tooltip>
                            </p>
                            <div class="option-item">
                                <a-select placeholder="All" class="source-select" v-model:value="modelRef.sourceId"
                                    :options="sourceOptions" ref="sourceSelect" :open="sourceSelectOpen"
                                    @dropdownVisibleChange="visible => sourceSelectOpen = visible"
                                    @change="onChangeTrackLink">
                                    <template #dropdownRender="{ menuNode: menu }">
                                        <v-nodes :vnodes="menu" />
                                        <a-space class="source-select-footer" :getContainer="() => $refs.sourceSelect">
                                            <a-input ref="inputRef" v-model:value="newSourceName"
                                                placeholder="Please enter the Source Name/ID." />
                                            <artmisads-select-button @click="onAddSourceOption">
                                                New Source
                                            </artmisads-select-button>
                                        </a-space>
                                    </template>
                                </a-select>
                            </div>
                        </div>
                    </div>
                </a-collapse-panel>
            </a-collapse>
        </div>
    </artmisads-modal>
</template>

<script setup>
import { reactive, ref, computed, watch, watchEffect, defineComponent } from 'vue';
import { message } from 'ant-design-vue';
import { SaveOutlined, CopyOutlined, QuestionCircleOutlined, PlusOutlined } from '@ant-design/icons-vue';

import { publisherProductApi } from '~/server/index'

const open = defineModel('open')
const { productId, trackLinkId, countryCode } = defineProps({ productId: Number, trackLinkId: Number || undefined, countryCode: String })
const emit = defineEmits('callback')

const sourceSelectOpen = ref(false)
const modelRef = ref({});
//缓存 source options 下拉数据
const sourceOptions = ref([])
const newSourceName = ref();

const VNodes = defineComponent({
    props: {
        vnodes: {
            type: Object,
            required: true,
        },
    },
    render() {
        return this.vnodes;
    },
});

const onChangeTrackLink = async () => {
    let { productId, trackingLink, amazonTagSelect, tag, sourceId } = modelRef.value
    let { success, data } = await publisherProductApi.getTrackLinkByParams({ productId, countryCode, trackingLink, amazonTagSelect, tag, sourceId })
    if (success) {
        modelRef.value.adGroupId = data.adGroupId
        modelRef.value.trackingLink = data.trackingLink
    }
}

const onAddSourceOption = async () => {
    if (newSourceName.value.trim()) {
        let { success, data } = await publisherProductApi.addSourceOption({ name: newSourceName.value })
        // let data = {name: 'Pinduoduo', id: 18, url: null}
        if (success) {
            sourceOptions.value.push({ label: data.name, value: data.id })
            modelRef.value.sourceId = data.id;
            sourceSelectOpen.value = false;
            onChangeTrackLink()
        }
    }
}

const onSave = async () => {
    let { success, data } = await publisherProductApi.saveGenerateLink(productId, countryCode, modelRef.value)
    if (success) {
        message.success('Success!', 1);
        let updateData = {
            trackLinkId: data.id,
            trackingLink: modelRef.value.trackingLink,
            shortLink: modelRef.value.shortLink,
            shortLinkSelect: modelRef.value.shortLinkSelect
        }
        emit('callback', updateData)
    }
}

const onSaveAndCopy = async () => {
    try {
        await navigator.clipboard.writeText(modelRef.value.shortLinkSelect ? modelRef.value.shortLink : modelRef.value.trackingLink)
        onSave();
    } catch (error) {
        message.success('Failed!', 1);
    }
}


watch(open, async open => {
    if (open) {
        if (!sourceOptions.value.length) {
            let { success, data } = await publisherProductApi.getSourceOptionsByPublisher()
            if (success) {
                let options = data.map(item => ({ label: item.name, value: item.id }))
                sourceOptions.value = options;
            }
        }
    }
}, { once: true })

watchEffect(async () => {
    if (open.value) {
        if (trackLinkId) {
            let { success, data } = await publisherProductApi.getTrackDetailByTrackLindId(trackLinkId)
            if (success) {
                modelRef.value = data
            }
        } else {
            let { success, data } = await publisherProductApi.getTrackDetailByProductId({ productId, countryCode })
            if (success) {
                modelRef.value = data
            }
        }
    }
})

</script>

<style scoped lang="less">
.link-tool {
    .title {
        display: block;
        text-align: center;
    }

    .link-textarea {
        position: relative;
        background-color: #f5f5f5;
        height: 122px;
        border-radius: 4px;
        border: 1px solid #d5d5d5;
        box-sizing: border-box;
        padding: 20px 60px 20px 10px;
        line-height: 20px;
        word-break: break-word;
        overflow-y: scroll;

        button {
            position: absolute;
            right: 10px;
            top: 10px
        }
    }

    .link-short-link {
        margin: 10px 0;
        text-align: right;
    }

    .link-collapse {

        :deep(.ant-collapse-header) {
            padding: 0;
            align-items: center;
        }
    }

    .link-advanced-option {
        .sub-title {
            font-weight: bold;
            display: inline-flex;
            gap: 6px;
        }

        .option-item {
            margin: 6px 0;

            .tracking-tag {
                display: flex;
                flex-direction: column;
                gap: 4px;

                label {
                    font-weight: normal;
                    font-size: 14px;
                }

                a {
                    margin-left: 20px;
                    text-decoration: underline;
                    color: rgba(0, 0, 0, .88);
                    font-size: 12px;
                    font-weight: bold
                }
            }

            .track-tag-input {
                display: block;
                margin: 6px 0 0 24px;
                width: 200px;
            }

            .source-select {
                display: block;
                margin-left: 24px;
                width: 400px;
            }

            .add-source {
                margin: 6px 0 0 24px;
            }
        }

    }
}

.source-select-footer {
    border-top: 1px solid #ECE6F2;
    padding: 10px 6px;
    width: 100%;

    :nth-child(1) {
        flex: 1;
    }
}
</style>