<template>
    <main class="main_container">
        <a-skeleton-image class="skeleton-loading" v-if="spinning" />
        <Gallery v-bind="gallery" v-else />
        <div class="product">
            <a-skeleton :loading="spinning">
                <Information class="box" v-bind="info" />
            </a-skeleton>
            <a-skeleton :loading="spinning">
                <LinkArea class="box" v-bind="trackLink" />
            </a-skeleton>
        </div>
    </main>
</template>

<script setup>
import { provide } from 'vue'
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { message } from 'ant-design-vue';

import Gallery from './gallery.vue';
import Information from './information.vue';
import LinkArea from './linkArea.vue';

import { publisherProductApi } from '~/server/index'

const route = useRoute();
const { id: productId } = route.params

provide('productId', parseInt(productId))

const trackLink = ref({
    id: 0,
    value: '',
    countryCode: ''
})

const info = ref({
    asin: '',
    brandName: '',
    category: '',
    currencySymbol: '',
    price: null,
    availiability: null,
    commission: null,
    productStarRating: '',
    productNumRatings: null,
    countryCode: '',
    otherCountryCodes: [],
    bestSellerRank: null
})

const gallery = ref({
    imageUrl: '',
    title: ''
})

const spinning = ref(true);

const getProductDetail = async () => {
    if (!productId) return
    spinning.value = true;
    const res = await publisherProductApi.getProductDetail(productId).catch(e => message.error('System error, please try again later.'))
    const { success, data } = res;
    if (success) {
        trackLink.value.id = data.latestTrackingLink?.id || 0
        trackLink.value.value = data.latestTrackingLink?.shortLinkSelect ? data.latestTrackingLink?.shortLink : data.latestTrackingLink?.trackingLink
        trackLink.value.countryCode = data.countryCode

        let { asin, brandName, category, currencySymbol, price, availiability, commission, productStarRating, productNumRatings, countryCode, otherCountryCodes, bestSellerRank } = data;
        info.value = { asin, brandName, category, currencySymbol, price, availiability, commission, productStarRating, productNumRatings, countryCode, otherCountryCodes, bestSellerRank }

        let { imageUrl, title } = data;
        gallery.value = { imageUrl, title };
    }
    spinning.value = false;
}

onMounted(() => {
    getProductDetail();
})

</script>

<style scoped lang="less">
.main_container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 15px;
    gap: 15px;

    .skeleton-loading {
        :deep {
            .ant-skeleton-image {
                width: 300px;
                height: 300px;
                line-height: 300px;
            }
        }
    }
}

.product {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .box {
        border: 1px solid #f0f0f0;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 1px 0px 6px #f0f0f0;
        padding: 0 16px;
        background-color: #fff;
    }
}
</style>