<template>
    <div class="link">
        <div class="link-head">
            <span class="link-head-title">Your Link for this Product</span>
            <span class="link-head-extra"><strong>Source: </strong>All</span>
        </div>
        <div class="link-body" v-if="trackLinkId">
            <div class="link-textarea">
                <artmisads-select-button @click="onCopyCode">
                    <template #icon>
                        <CopyOutlined />
                    </template>
                </artmisads-select-button>
                {{ trackLink }}
            </div>
            <div class="link-opt">
                <artmisads-select-button @click="() => onShowLinkModal()">
                    <template #icon>
                        <PlusOutlined />
                    </template>
                    Create New Link
                </artmisads-select-button>
                <a-space>
                    <artmisads-select-button type="text">Performance</artmisads-select-button>
                    <artmisads-select-button @click="() => onShowLinkModal(trackLinkId)">
                        <template #icon>
                            <PlusOutlined />
                        </template>
                        Edit Link
                    </artmisads-select-button>
                </a-space>
            </div>
        </div>
        <div class="link-body" v-else>
            <div class="link-new">
                <p>You have no saved links for this Product</p>
                <artmisads-select-button @click="() => onShowLinkModal()">
                    <template #icon>
                        <PlusOutlined />
                    </template>
                    Create New Link
                </artmisads-select-button>
            </div>
        </div>
        <!-- 两种场景 -->
        <!-- 从未生成过Track Link的需要传productId -->
        <!-- 生成过Track Link的则需要传trackLinkId -->
        <LinkToolModal :productId="productId" :trackLinkId="trackLinkId" :countryCode="countryCode" v-model:open="open"
            @callback="onSaveCallback" />
    </div>
</template>

<script setup>
import { ref, inject, watchEffect } from 'vue'
import { message } from 'ant-design-vue';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons-vue';

import LinkToolModal from './linkToolModal.vue';

const open = ref(false);

const productId = inject('productId')

const { id, value, countryCode } = defineProps({ id: Number || undefined, value: String, countryCode: String })

const trackLinkId = ref(id)
const trackLink = ref(value)

const onShowLinkModal = openTrackLinkId => {
    trackLinkId.value = openTrackLinkId
    open.value = true
}

const onCopyCode = async () => {
    if (link.value) {
        try {
            await navigator.clipboard.writeText(link.value)
            message.success('Copied!', 1);
        } catch (error) {
            message.success('Failed!', 1);
        }
    }
}

const onSaveCallback = updateData => {
    trackLinkId.value = updateData.trackLinkId;
    trackLink.value = updateData.shortLinkSelect ? updateData.shortLink : updateData.trackLink;
    open.value = false;
}

</script>

<style scoped lang="less">
.link {
    .link-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 48px;
        border-bottom: 1px solid #f0f0f0;

        .link-head-title {
            font-weight: 600;
            font-size: 16px;
        }
    }

    .link-body {
        padding: 16px 0;

        .link-new {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            height: 160px;
            align-items: center;
            gap: 20px;
            width: 100%;

            p {
                font-size: 16px;
                font-weight: bold;
            }
        }

        .link-textarea {
            position: relative;
            background-color: #f5f5f5;
            height: 122px;
            border-radius: 4px;
            border: 1px solid #d5d5d5;
            box-sizing: border-box;
            padding: 20px 60px 20px 10px;
            line-height: 20px;
            word-break: break-word;
            overflow-y: scroll;

            button {
                position: absolute;
                right: 10px;
                top: 10px
            }
        }

        .link-opt {
            margin-top: 16px;
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
        }
    }
}
</style>