<template>
    <artmisads-modal class="commission-history-modal" :width="960" v-model:open="open" :footer="null"
        :maskClosable="false">
        <template #title>
            <span class="title">Commission History</span>
        </template>
        <div ref="chartRef" class="chart"></div>

        <div class="commission-history">
            <artmisads-table :columns="columnsHistory" row-key="id" :pagination="false" rowClassName="table-item"
                :data-source="commissionHistoryList" />
            <p v-if="commissionHistoryList.length">Commissions are locked for a given day at 11:59pm Eastern the night
                before.
                The current commission value will become tomorrow's locked-in commission value at that time.</p>
        </div>
    </artmisads-modal>
</template>

<script setup>
import { ref, computed, watch, watchEffect, onMounted } from 'vue';

import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';

import moment from 'moment-timezone';

import { publisherProductApi } from '~/server/index'
import { color } from 'echarts';

const { productId } = defineProps({ productId: Number })

const open = defineModel('open')
const chartRef = ref()
const commissionHistoryChart = ref(null)

const commissionHistoryList = ref([])

const columnsHistory = [
    {
        title: 'Date',
        dataIndex: 'startDate',
        customRender: ({ text, record }) => moment(text).format('YYYY/MM/DD')
    },
    {
        title: 'Commission',
        dataIndex: 'commission',
        customRender: ({ text, record }) => `${Number(text).toFixed(1)}%`
    },
    {
        title: 'Difference',
        dataIndex: 'difference',
    },
];

onMounted(() => {
    echarts.use([LineChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, CanvasRenderer])
})

watch([open, chartRef, commissionHistoryChart], async ([open, chartRef, commissionHistoryChart]) => {
    if (open && chartRef) {
        if (!commissionHistoryChart) {
            commissionHistoryChart = echarts.init(chartRef)
        }
        let { success, data } = await publisherProductApi.getCommissionHistoryByProductId(productId)

        if (success) {
            commissionHistoryList.value = data;

            let chartOptions = {
                grid: {
                    left: '1%',
                    right: '1%',
                    bottom: '5%',
                    top: '5%',
                    containLabel: true,
                },
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#fff',
                    borderColor: '#fff',
                    extraCssText: 'box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);',
                },
                xAxis: {
                    type: 'category',
                    data: (() => {
                        let optData = data.map((item, index) => moment(item.startDate).format('MMM DD')).reverse()
                        optData.push('Current')
                        return optData
                    })(),
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(15, 6, 41, .5)'
                        }
                    },
                    axisLabel: {
                        fontSize: 12
                    }
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        formatter: '{value}%'
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(15, 6, 41, .7)'
                        }
                    }
                },
                series: [
                    {
                        data: (() => {
                            let optData = data.map(item => item.commission).reverse()
                            optData.push(data[0].commission)
                            return optData
                        })(),
                        step: 'end',
                        type: 'line',
                        color: '#835CF2',
                        lineStyle: {
                            width: 2
                        },
                        areaStyle: {
                            color: {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgb(131, 92, 242, .3)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: 'rgb(131, 92, 242, 0)' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            },
                        }
                    }
                ]
            }
            commissionHistoryChart.setOption(chartOptions)
        }
    }
})

</script>

<style scoped lang="less">
.chart {
    height: 300px;
}

.commission-history {
    margin-top: 30px;

    >p {
        margin-top: 5px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 22px;
        color: var(--dark-3);
    }
}
</style>